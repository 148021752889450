<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
    </div>
    <Table
      v-if="getTableOfVotingHistoryCommissioner.table"
      :content="getTableOfVotingHistoryCommissioner.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';

export default {
  components: { Table },

  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getTableOfVotingHistoryCommissioner');
  },

  computed: {
    ...mapGetters(['getTableOfVotingHistoryCommissioner']),
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfVotingHistoryCommissioner');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfVotingHistoryCommissioner');
    },
  },
};
</script>

<style lang="sass" scoped>
.table__header_bar.input-search__form
  @include xs
    width: 300px
</style>
